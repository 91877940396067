import Example from "components/concepts/templates/Example";
import ExampleContainer from "components/concepts/templates/ExampleContainer";
import Image from "components/concepts/templates/Image";

export default function DefineVisionPathway() {
  return (
    <div>
      <p>
        A vision is an aspirational goal or event that should happen if the company fulfills its
        stated mission. It can describe what the world will look like afterward, it could be what
        the company will become afterward, or what will ultimately be accomplished. For certain, it
        must be motivational but doesn't need to be strategic. It should be specifically unique to
        the company that made it. It needs to be hard to reach in that the company itself has to
        stretch to reach it. This makes a vision different than a mission, becaues visions have a
        finish line and should guide strategy development.
      </p>
      <div>
        The vision statement should meet the following criteria:
        <ul>
          <li>Be consistent with a well researched strategy</li>
          <li>Reflect a choice about how to compete</li>
          <li>Articulate an inspiring future</li>
          <li>Be specific and measurable</li>
          <li>Be short, cleaer, and concise</li>
          <li>Be unique to the company</li>
        </ul>
      </div>
      <ExampleContainer>
        <Example header="Tesla">
          <p>
            "To create the most compelling car company of the 21st century by driving the world's
            transition to electric vehicles."
          </p>

          <p>
            This has a specific finish line, clearly states how they will compete, and is
            specifically Tesla. No other company can have this statement.
          </p>
        </Example>
        <Example header="Abysse">
          <p>"Produce less, produce well."</p>
          <p>
            This sounds and feels good, but it doesn't seem like a vision statement in that it
            doesn't look forward to a future day where they finish. It speaks how they will compete,
            its short and catchy, but lacks the ultimate goal of what they're trying to achieve.
          </p>
        </Example>
        <Example header="Apple">
          <p>
            "To make the best products on earth and to leave the world better than we found it."
          </p>
          <p>
            This specifies how to compete and has an inspiring future. Its also lacking specifics
            andis hard to measure. Ultimately, it has two statements that contradict each other.
            They might make the best products on earth but they obsolete last years models every
            year. If they are serious about their vision, their recycling program needs to be just
            as robust as their advertising for their new tech.
          </p>
        </Example>
        <Example header="Coca Cola">
          <p>
            "Craft the brands and choice of drinks that people love, to refresh them in body &
            spirit. And done in ways that create a more sustainable business and better shared
            future that makes a difference in people's lives, communities and our planet."
          </p>

          <p>
            This is their stated vision, but it seems more like a mission statement, as it has no
            end goal but it does have strategy. It has an add-on sentence that, similar to Apple,
            doesn't seem to fit with the first bit and is incredibly generic.
          </p>

          <p>They do have a purpose statement, which is:</p>

          <p>"Refresh the world. Make a difference."</p>

          <p>
            This seems to align better with a vision statement, though it still lacks some of the
            key ingredients for a good one. If both were combined and rearranged, a mission and
            vision could be identified.
          </p>
        </Example>
      </ExampleContainer>

      <div className="px-3 py-2 bg-light rounded border">
        <p>
          The framework below helps evaluate the mission, vision, and value statements, indivudally
          and collectively, to decide if they are motivational, have strategy, and if those are
          coherent with each other.
        </p>
        <p>
          Patagonia's mission, "We're in business to save our home planet," is very inspirational
          but has no strategy to it at all. They instead stretch their Values to include how they
          will make this happen. In this way, the culmination of their mission, vision, and values
          show motivation, strategy, and cohesion.
        </p>
        <Image src={require("lib/img/pathways/DefineVision.png")} alt="" />
      </div>
    </div>
  );
}
