import { useContext, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import { LoginAttempt } from "api/auth/LoginAttempt";
import UserContext from "context/UserContext";
import VerifyOtp from "components/auth/OtpVerifier";
import LoginBG from "lib/img/loginBG.png";

export default function Login() {
  SetDocumentTitle("Login");
  const [emailVerified, setEmailVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailVerificationRequired, setEmailVerificationRequired] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();

  const searchParams = useSearchParams()[0];
  let return_url = searchParams.get("return_url") ?? "/";
  let verified = searchParams.get("verified");

  const { loginStatus } = useContext(UserContext);
  if (loginStatus === "logged in") {
    navigate(return_url);
  }

  async function LoginAttemptHandler(e: any) {
    e.preventDefault();
    const email = e.target["email"].value;
    const password = e.target["password"].value;

    const data = await LoginAttempt(email, password);

    if (data.status === "success") {
      localStorage.setItem("userId", String(data.userId));
      localStorage.setItem("token", data.token);
      window.location.href = return_url;
    } else {
      if (data.error === "emailUnverified") {
        setUserEmail(email);
        setEmailVerificationRequired(true);
        document.getElementById("otp-anchor")?.scrollIntoView();
      } else {
        setErrorMessage(data.message);
        document.getElementById("error-anchor")?.scrollIntoView();
      }
      console.error(data);
    }
  }

  if (emailVerified) {
    navigate("/login/?return_url=" + return_url + "&verified=true");
  }

  return (
    <div className="w-100">
      <div className="" style={{ minHeight: window.innerHeight }}>
        <div>
          <div
            id="login-bg"
            className="d-flex position-relative flex-wrap justify-content-between"
            style={{
              minHeight: window.innerHeight,
            }}
          >
            <img
              className="position-fixed object-fit-cover h-100 w-100 z-n1"
              src={LoginBG}
              alt="strategy unlocked"
              style={{ opacity: "0.5", objectPosition: "right" }}
            />
            {/* input card */}
            <div className=" p-2 p-md-5 " style={{ width: 600, maxWidth: 600 }}>
              <div className="d-block p-4 w-100 shadow bg-body mx-auto mx-md-0 rounded">
                <div className="">
                  <h1>Welcome Back!</h1>
                  {verified && (
                    <div
                      className="text-center text-bg-success rounded my-2 py-1 px-2"
                      style={{ opacity: 1 }}
                    >
                      Your email has been verified! You can now log in.
                    </div>
                  )}
                  <div className="position-relative">
                    <div className="position-absolute" style={{ top: -100 }} id="error-anchor" />
                  </div>
                  {errorMessage && (
                    <div className=" my-4">
                      <div className="alert alert-danger">
                        <button
                          type="button"
                          className="btn-close position-absolute top-0 end-0 m-2"
                          aria-label="Close"
                          onClick={() => setErrorMessage("")}
                        ></button>
                        {errorMessage}
                      </div>
                    </div>
                  )}
                  <div className="lead d-flex flex-wrap">
                    Don't have an account?&nbsp;
                    <Link to={"/register/?return_url=" + return_url}>Register here</Link>
                  </div>
                </div>

                <div>
                  <form onSubmit={LoginAttemptHandler} className="form-control">
                    <div className="m-2 my-4">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        name="email"
                        className="form-control"
                        type="email"
                        autoComplete="email"
                        placeholder="Development email is 'test@byu.edu'"
                        required
                      />
                    </div>
                    <div className="m-2 my-4">
                      <label htmlFor="password" className="">
                        <div>Password</div>
                      </label>

                      <input
                        id="password"
                        name="password"
                        className="form-control"
                        type="password"
                        autoComplete="current-password"
                        placeholder="Development password is 'ByuSuTeam'"
                        required
                      />
                      <Link to={"/forgotpassword/?return_url=" + return_url}>
                        <small>Forgot Password?</small>
                      </Link>
                    </div>

                    <div className="d-flex">
                      <input
                        type="submit"
                        className="w-100 btn btn-primary m-2 mt-3"
                        value={"Log In"}
                      />
                    </div>
                  </form>

                  <VerifyOtp
                    email={userEmail}
                    setEmailVerified={setEmailVerified}
                    showVerifier={emailVerificationRequired && !emailVerified}
                  />
                </div>
              </div>
            </div>
            <div className=" p-2 p-md-5 " style={{ width: 600, maxWidth: 600 }}>
              <div className="text-secondary bg-white p-3 rounded">
                Development notes: The email and password are:
                <ul>
                  <li>Email = "test@byu.edu"</li>
                  <li>Password = "ByuSuTeam"</li>
                </ul>
                This will allow you to access the paid content.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
