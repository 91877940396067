import Example from "components/concepts/templates/Example";
import ExampleContainer from "components/concepts/templates/ExampleContainer";
import Image from "components/concepts/templates/Image";
import RelatedFrameworks from "components/concepts/templates/RelatedFrameworks";

export default function PainPointsPathway() {
  return (
    <div>
      <p>
        Because no product or service is perfect, customers always experience some level of
        frustration when using them. These are pain points. Businesses can uncover pain points by
        seeing customers engage with their current solutions as they complete their "jobs".
      </p>
      <p>
        A great way to uncover pain points is by walking through consumers experiences via user
        journey mapping. This consider five main elements:
      </p>
      <ol>
        <li>Actor: the person who experiences the journey, whose point of view we will take on</li>
        <li>
          Scenario + Expectations: the situation addressed by the map, it's best if it is a sequence
          of events, a process, involves multiple channels;can be real or anticipated.
        </li>
        <li>
          Journey Phases: the different high-level stages in the journey within which actions,
          thoughts, and emotions are categorized
        </li>
        <li>
          Actions, Mindsets, and Emotions: Actions: a narrative of the steps taken by the actor
          during a given phase; Mindsets: thoughts, questions motivations and information needs,
          ideally verbatim from actors; emotions: signals the emotional ups and downs of the
          experience - delighted vs. frustrated
        </li>
        <li>Opportunities: insights gained from mapping about how to improve users' experience</li>
      </ol>

      <ol>
        <li>
          Determine who your actor is. This should be a current customer profile, or a desired
          future customer profile.
        </li>
        <li>Choose the scenario you want to map out, with it's ideal outcome</li>
        <li>Segment the journey into phases / touchpoints</li>
        <li>Envision and map user actions and responses</li>
        <li>Validate with target audience and iterate</li>
        <li>Summarize opportunities to improve</li>
      </ol>
      <ExampleContainer>
        <Example header="Landscaping">
          Imagine you own a residential landscaping business and you want to understand the
          frustrations that customers have with finding a good landscaper to maintain their
          property. First, you choose your actor - home owners 55 and older who are still working
          full-time. The scenario you choose is them deciding they no longer want to maintain their
          yard themselves, because they want to use their time and energy on other activities, and
          can afford to hire a landscaper, with the expectation being that they find a good company
          to use. You segment this scenario up into main phases of search, comparison, evaluation,
          and selection. You walk through what these customers actually do, think, and feel as they
          search for landscapers, evaluate them to decide which ones are competent and trustworthy,
          compare them against each other, and finally, select one to hire. Then, you validate this
          journey map with actual people who fit your actor profile. After you make a few
          corrections to the journey map, you review what opportunities you have as a landscaper to
          improve customers' experience in this journey to minimize their pain points.
        </Example>

        <Example header="Spotify">
          <p>
            Spotify wanted to improve its music-sharing experience for its customers, so it hired a
            marketing firm to create a customer journey map.
          </p>
          <p>
            The goal of this user journey map was to determine where music sharing features the best
            fit into the customer experience.
          </p>
          <div className="my-3">
            <Image src={require("lib/img/pathways/PainPoints.png")} alt="Spotify Pain Points" />
          </div>

          <p>
            They mapped out the user experience mapped from the moment the user first opens Spotify
            on a mobile device, all the way through to whether they like a song that a friend has
            shared.
          </p>
          <p>
            Throughout each stage and every touchpoint, the brand lists what a customer is engaging
            with, doing, thinking, and feeling. The agency surveyed customers to understand how
            users felt at each touchpoint in the customer journey to collect this information.
          </p>
          <p>
            Based on the customer journey map, Spotify was able to identify pain points for users
            and address those pain points so that the music sharing experience is smooth and
            seamless, encouraging more users to share music -- and to do it more often."
          </p>
        </Example>
      </ExampleContainer>
      <RelatedFrameworks unfinishedConcepts={["Journey Mapping Framework"]} />
    </div>
  );
}
