import { GetFocusesView, TFullFocusView, TPathwayView, TFocusView } from "lib/data/Views";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import Pathways from "lib/data/Pathways";
import constants from "Constants";
import { SidebarContext } from "components/templates/SidebarPage";
import HouseIcon from "components/bootstrap_icons/House";
import LogoSvg from "../LogoSvg";
import BookIcon from "../bootstrap_icons/Book";

type Prettify<T> = {
  [K in keyof T]: T[K] extends object ? Prettify<T[K]> : T[K];
} & unknown;

type TPrettyFocusView = Prettify<Prettify<TFullFocusView>>;

function ConceptAccordionItem({
  pathway,
  conceptId,
  pageConcept,
  k,
}: {
  pathway: TPathwayView;
  conceptId: string;
  pageConcept: string | undefined;
  k: number;
}) {
  const concept = pathway.concepts[conceptId];
  return (
    <li className="lh-1 my-2" key={k} style={{ marginLeft: 20 }}>
      <Link
        key={k}
        to={concept.url + "/" + pathway.id}
        className={
          "no-underline underline-on-hover text-body " +
          (conceptId === pageConcept ? "fw-bold" : "")
        }
      >
        {concept.name}
      </Link>
    </li>
  );
}

function PathwayAccordionItem({
  focus,
  pathwayId,
  pagePathway,
  pageConcept,
}: {
  focus: TFocusView;
  pathwayId: string;
  pagePathway: string | undefined;
  pageConcept: string | undefined;
}) {
  const pathway = focus.pathways[pathwayId];
  return (
    <Accordion.Item eventKey={pathwayId} className="rounded-0">
      <Accordion.Header style={{ backgroundColor: "#aaa2" }}>
        <Link
          to={pathway.url}
          className="ms-4 no-underline text-body underline-on-hover my-3 "
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span className={pathwayId === pagePathway ? "fw-bold" : ""}>{pathway.name}</span>
        </Link>
      </Accordion.Header>
      <Accordion.Body className="py-2 bg-tan-3">
        <ul className="dashed">
          {Object.keys(pathway.concepts).map((conceptId, k) => (
            <ConceptAccordionItem
              key={k}
              pathway={pathway}
              conceptId={conceptId}
              pageConcept={pageConcept}
              k={k}
            />
          ))}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
}

type TFocusAccordionItemProps = {
  PageTree: TPrettyFocusView;
  focusId: string;
  pageFocus: string | undefined;
  pagePathway: string | undefined;
  pageConcept: string | undefined;
};

function FocusAccordionItem({
  PageTree,
  focusId,
  pageFocus,
  pagePathway,
  pageConcept,
}: TFocusAccordionItemProps) {
  const focus = PageTree[focusId];
  const { sidebarCollapsed } = useContext(SidebarContext);
  return (
    <Accordion.Item eventKey={focusId}>
      <Accordion.Header>
        <Link
          to={focus.url}
          className="no-underline text-body underline-on-hover d-flex my-3"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="d-flex justify-content-center align-items-center ">
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ width: constants.sidebarCollapsedWidth + "em" }}
            >
              <img
                src={require(`lib/img/letters/${focus.letter}.png`)}
                height="20"
                alt=""
                style={{ filter: constants.colors.darkTanCssFilter }}
              />
            </div>
            <h5 className={"my-0 ms-1 me-4 " + (pageFocus === focus.id ? "fw-bold " : "")}>
              {focus.name}
            </h5>
          </div>
        </Link>
      </Accordion.Header>
      <Accordion.Body className={`p-0 ${sidebarCollapsed && "collapse"}`}>
        <Accordion defaultActiveKey={pagePathway && pageConcept ? pagePathway : ""} flush>
          {Object.keys(focus.pathways).map((pathwayId, j) => (
            <PathwayAccordionItem
              key={j}
              pathwayId={pathwayId}
              focus={focus}
              pagePathway={pagePathway}
              pageConcept={pageConcept}
            />
          ))}
        </Accordion>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function SidebarMainLink({ Icon, url, title }: { Icon: any; url: string; title: string }) {
  return (
    <Link to={url} className="no-underline text-body  ">
      <div className="py-3 sidebar-title border-bottom d-flex bg-tan-3-hover">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: constants.sidebarCollapsedWidth + "em" }}
        >
          <div className="w-50">
            <Icon />
          </div>
        </div>
        <h5 className="mb-0 lead">{title}</h5>
      </div>
    </Link>
  );
}

export default function MasterSidebar() {
  const PageTree: TPrettyFocusView = GetFocusesView();
  const path = useLocation().pathname;
  const pathArray = path.split("/").filter((path) => path !== "");

  let pageFocus: string | undefined;
  let pagePathway: string | undefined;
  let pageConcept: string | undefined;

  if (pathArray[0] === "focus") {
    pageFocus = pathArray[1];
  } else if (pathArray[1] === "pathway") {
    pagePathway = pathArray[2];
    pageFocus = Pathways[pathArray[2]].focus;
  } else if (pathArray[1] === "concept") {
    pageConcept = pathArray[3];
    pagePathway = pathArray[4];
    pageFocus = Pathways[pagePathway]?.focus;
  }

  return (
    <div className="mb-5">
      <SidebarMainLink
        Icon={() => HouseIcon({ fill: constants.colors.greyBlue })}
        url="/"
        title="Home"
      />
      <SidebarMainLink
        Icon={() => LogoSvg({ filter: constants.colors.greyBlueCssFilter, className: "w-100" })}
        url="/codex"
        title="The Codex"
      />
      <SidebarMainLink
        Icon={() => BookIcon({ fill: constants.colors.greyBlue })}
        url="/FocusHome"
        title="FOCUS"
      />
      <Accordion className="" defaultActiveKey={pageFocus && pagePathway ? pageFocus : ""} flush>
        {Object.keys(PageTree).map((focusId) => (
          <FocusAccordionItem
            key={focusId}
            PageTree={PageTree}
            focusId={focusId}
            pageFocus={pageFocus}
            pagePathway={pagePathway}
            pageConcept={pageConcept}
          />
        ))}
      </Accordion>
    </div>
  );
}
