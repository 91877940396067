import Definition from "../templates/Definition";
import Image from "../templates/Image";
import ExampleContainer from "../templates/ExampleContainer";
import Example from "../templates/Example";
import History from "../templates/History";
import { Link } from "react-router-dom";
import Tools from "lib/data/Tools";

import PortersFiveForcesImage from "lib/img/concepts/Porter's_Five_Forces.png";

export default function PortersFiveForces() {
  return (
    <div>
      <div className="d-flex justify-content-center">
        <Link to={Tools.PortersFiveForcesCalculator.url}>
          <button className="btn btn-primary mb-3 mx-auto">
            Go to Porter's Five Forces Calculator Tool
          </button>
        </Link>
      </div>
      <Definition>
        <p>
          Many business leaders struggle to objectively analyze their industries. Too often, they
          become obsessed with understanding competitors and fail to recognize alternate (and
          sometimes more destructive) threats. Porter's Five Forces is the seminal model to
          understanding the underlying factors of any industry.
        </p>

        <p>
          As an example, Southwest Airlines - a global low-cost carrier - used the model to develop
          a low-cost carrier strategy. After recognizing the forces at work, Southwest created
          differentiation by offering low-cost fares to passengers.
        </p>
      </Definition>
      <Image src={PortersFiveForcesImage} alt="Porter's Five Forces" />
      <ExampleContainer>
        <Example header="Amazon">
          The international product supplier, used the Five Forces model to expand from books into
          related markets such as books, music electronics etc. They recognized the cost
          efficiencies driven by an understanding of supplier and buyer power.
        </Example>
        <Example header="Apple">
          A modern technology innovator, engaged the model to create a successful product strategy.
          They focused their brand on consumer-friendly electronics to develop a differentiated
          product line that enjoys a wide appeal.
        </Example>
      </ExampleContainer>
      <History>
        <p>
          The first mention of competitive forces was in a 1979 Harvard Business Review article
          titled “How Competitive Forces Shape Strategy,” written by Michael E. Porter. It was
          composed after the observation that contemporary business often focused much of their
          strategic efforts on competitors while ignoring underlying forces in the wider business
          environment. Post publication, the article was widely circulated, introducing the idea to
          vast audience. To further hone his points of strategic doctrine, Professor Porter
          published his seminal treatise, Competitive Strategy in 1980. In this book, he detailed
          how to accurately use the Five Forces model to analyze the competitiveness of industries.
        </p>
        <p>
          Throughout the 1980s and 1990s, businesses began to compete around the world. With this
          rise of global business competition, the Five Forces model became increasingly relevant.
          It was adopted, used and scrutinized to assist business leaders achieve their ends.
          Finally, model was immortalized through it's vast dissemination in MBA programs and
          business schools. These institutions became viewed as highly valuable from the increased
          competition of global business of 80s and 90s. Thus, the Five Forces model went around the
          globe and is still taught today as a simple and effective strategic model.
        </p>
      </History>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}
