import Students from "lib/data/Students";
import { TStudent } from "types/JsonType";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import GublerImg from "lib/img/team/t_gubler.jpg";
import OldroydImg from "lib/img/team/j_oldroyd.jpg";
import TeamBG from "lib/img/team/strategy-instructors.jpg";

function getContribution(student: TStudent) {
  let contribution = 0;
  if (student.started) {
    const today = new Date();
    const diff = today.getTime() - student.started.getTime();
    contribution = diff / (1000 * 60 * 60 * 24 * 365);
  }
  return contribution;
}

export default function Team() {
  SetDocumentTitle("Meet the Team");
  Students.sort((a, b) => getContribution(b) - getContribution(a));

  return (
    <div className="h-100 mb-5 w-100">
      <img src={TeamBG} alt="" className="w-100" />

      <div className="container my-5 " style={{ maxWidth: 1106 }}>
        <div>
          <div className="display-1 text-center mt-5">The Team </div>
          <div className="display-4 text-center">Professors</div>
          <hr />
        </div>
        <div className="row my-4">
          <div className="col-md-3 mb-2 mb-md-0">
            <img className="w-100" src={OldroydImg} alt="" style={{ width: "300px" }} />
          </div>
          <div className="col-md-9">
            <strong>Professor James Oldroyd</strong> is an associate professor of Strategy at the
            Marriott School of Business, Brigham Young University and the Ford Motor/Richard Cook
            Research Fellow. He received his Ph.D. from the Kellogg School of Management at
            Northwestern University. He was an associate professor of management at SKK-GSB in
            Seoul, South Korea for five years and an assistant professor of international business
            at The Ohio State University for three years. His research explores the intersection of
            strategy, innovation, networks, and knowledge flows. He has published in top research
            outlets such as the Academy of Management Review, Organization Science and Harvard
            Business Review. He teaches courses on strategy, strategy implementation, international
            business, and negotiations to undergraduates, MBAs, and executives. He is actively
            involved in consulting and delivering custom leadership training courses for numerous
            companies.
          </div>
        </div>
        <div className="row my-4">
          <div className="col-md-3 mb-2 mb-md-0">
            <img className="w-100" src={GublerImg} alt="" />
          </div>
          <div className="col-md-9">
            <strong>Professor Timothy Gubler</strong> is an associate professor of Strategy at the
            Marriott School of Business, Brigham Young University. He received his Ph.D. from
            Washington University in St. Louis and has held faculty appointments at BYU and the
            University of California (Riverside). His research focuses on understanding the
            determinants of individual productivity and performance, with a focus on high knowledge
            and skill workers. Prior work has focused on the residential real estate industry,
            commercial laundry factories, emergency medical service professionals (i.e.,
            paramedics), and federal workers. His work has been published in top journals including
            Management Science, Organization Science, the Strategic Management Journal, and
            Psychological Science, and has won multiple academic awards. It has also been featured
            in major news outlets including the New York Times, the Wall Street Journal, the
            Financial Times, Forbes, NPR, USA Today, the Huffington Post, and many local papers. Tim
            is currently a senior editor at Organization Science, a top Business Management Journal.
            He teaches courses on business strategy to MBAs at BYU Marriott as well as to
            undergraduates. Prior to joining academia Tim worked as an electrician and company
            manager for his family’s electrical contracting business. Tim is a father of 5 kids and
            enjoys building things and being outdoors.
          </div>
        </div>
        <div className="display-4 text-center mt-5">Students</div> <hr />
        <div className="d-flex flex-wrap w-100 justify-content-center align-items-center">
          {Students.map((student, i) => {
            return (
              <div
                className="card p-0 m-3"
                key={student.lastName}
                style={{ width: 300, height: 520 }}
              >
                <img
                  src={student.imgSrc}
                  alt=""
                  className="card-img-top object-fit-cover"
                  style={{ height: 335, objectPosition: "top" }}
                />
                <div className="card-body">
                  <h5 className="card-title">
                    {student.firstName} {student.lastName}
                  </h5>
                  <div className="card-text lh-1 mb-2">{student.major}</div>
                  <div className="card-text lh-1 mb-2">{student.role}</div>
                  {student.linkedin ? (
                    <div className="card-text">
                      <a
                        className="text-body"
                        href={student.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <small>LinkedIn Profile</small>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="card-text"></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
