import constants from "Constants";
import { Link } from "react-router-dom";
import { CodexSvg } from "./Codex";
import Videos from "lib/data/Videos";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import LogoSvg from "components/LogoSvg";

function MainPanel() {
  return (
    <div id="main-panel" className="overflow-hidden py-5 ">
      <div style={{ maxWidth: constants.maxPageWidth }} className="mx-auto position-relative">
        <div className="position-absolute d-flex justify-content-end align-items-center h-100 w-100">
          <div className="h-100 d-flex justify-content-center align-items-center w-25">
            <LogoSvg
              filter={constants.colors.tan3CssFilter}
              className="codex-logo-spin d-none d-sm-block position-absolute "
              style={{
                height: "150%",
              }}
            />
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-lg-7">
              <div className="card border-0 shadow p-4">
                <div className="card-body">
                  <div className="display-4 fw-bold mb-1">
                    <div className="text-dark-tan">No Strategy?</div>
                    <div className="text-grey-red">No Problem.</div>
                  </div>
                  <div>
                    <div className="lead fs-4 mb-4">
                      Get started today and <i>master</i> business strategy.
                    </div>
                    <hr className="my-4" />
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <h5>What is Business Strategy?</h5>
                        <p>
                          Strategy is the plan to achieve a goal. It will take you from where you
                          are to where you want to be.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <h5>Why is Strategy Important?</h5>
                        <p>
                          A powerful strategy eliminates waste. Focus your resources to maximize
                          value and achieve your company's purpose.
                        </p>
                      </div>
                    </div>

                    <Link className="btn btn-primary" to="/FocusHome">
                      Start Learning
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ToolsPanel() {
  const tools = [
    {
      title: "Strategy FOCUS",
      description: `Business can be very complicated, due to its countless moving parts. It takes energy, focused in the right areas, to make an impact. Just like a magnifying glass focuses light to create energy in the form of heat, there are key elements of business strategy that, if focused on, create true business results.`,
      url: "/FocusHome",
      imgSrc: require("lib/img/FocusTool.jpg"),
    },
    {
      title: "The Strategy Codex",
      description: `The Strategy Codex is a graphic representation of the strategy process. It is a
      collection of strategy frameworks and diagnostic tools, all in one place. If you want a brief
      overview of many strategy concepts, this is the place to start.`,
      url: "/codex",
      imgSrc: require("lib/img/CodexTool.jpg"),
    },
  ];
  return (
    <div id="tools-panel" className="shadow-lg">
      <div className="bg-grey-red" style={{ paddingBlock: 120 }}>
        <div className="text-white mx-auto mb-5" style={{ maxWidth: constants.maxPageWidth }}>
          <div className="display-4 text-center fw-bold  ">Strategy Tools</div>
          <div className="fs-4 text-center w-75 mx-auto">
            Get to know the tools that will help you develop a strategy that will guide your company
            to success.
          </div>
        </div>
        <div className="container ">
          <div className="row">
            {tools.map((tool, i) => {
              return (
                <div className="col-lg-6" key={tool.title}>
                  <div className="p-3 h-100">
                    <Link to={tool.url} className="text-body no-underline">
                      <div className="card rounded-0 shadow h-100 px-3 pb-0">
                        <div className="card-body px-4 py-4">
                          <h2 className="card-text text-center mb-4">{tool.title}</h2>
                          <img
                            className="object-fit-cover rounded"
                            src={tool.imgSrc}
                            alt=""
                            width={"100%"}
                          />
                          <div className="card-text fs-5 mt-4">{tool.description}</div>
                          <div
                            className="text-grey-red text-end fw-bolder me-4"
                            style={{ fontSize: 60 }}
                          >
                            &rarr;
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function CodexPanel() {
  const availableWidth = window.innerWidth * 0.95;
  const codexWidth = availableWidth < 1000 ? availableWidth : 1000;

  return (
    <div id="codex-panel" style={{ marginTop: 70 }}>
      <div className="mb-5">
        <div className="display-4 fw-bold text-darker-tan text-center">The Strategy Codex</div>
        <div className="fs-4 text-center">
          Our collection of strategy frameworks and diagnostic tools, all in one place.
        </div>
        <div className="lead text-center">
          click{" "}
          <Link to={"codex"} className="lead text-body">
            here
          </Link>{" "}
          to see it on its own page
        </div>
        <div className="py-4">
          <CodexSvg codexWidthProp={codexWidth} />
        </div>
      </div>
    </div>
  );
}

function VideosPanel() {
  return (
    <div id="videos-panel">
      <div>
        <div className="text-white bg-dark-tan " style={{ paddingTop: 100 }}>
          <div className="mx-auto" style={{ maxWidth: constants.maxPageWidth }}>
            <div className="text-white">
              <div className="display-4 text-center fw-bold">Video Resources</div>
              <div className="fs-4 text-center w-75 mx-auto">
                Access our library of strategy videos to get in-depth summaries of important
                strategy concepts.
              </div>
            </div>
            <div className="container mt-4">
              <div className="row ">
                {Videos.slice(0, 3).map((video, i) => {
                  return (
                    <div key={video.name} className="col-md-6 col-lg-4 my-2 px-3">
                      <div className="card p-0 border-0 h-100 shadow-lg-hover ">
                        <a
                          key={video.name}
                          href={video.url}
                          target="_blank"
                          rel="noreferrer"
                          className="no-underline text-body"
                        >
                          <div className="card-body">
                            <img className="w-100" src={video.thumbnail} alt={video.name} />
                            <h3 className="text-center">{video.name}</h3>
                            <div>{video.summary}</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="text-center mt-5">
              <Link className="btn btn-lg btn-primary mb-5" to="/videos">
                See all Videos
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Home() {
  SetDocumentTitle("Home");

  return (
    <div className="w-100">
      <MainPanel />
      <ToolsPanel />
      <CodexPanel />
      <VideosPanel />
    </div>
  );
}
