import constants from "Constants";
import UserContext from "context/UserContext";
import { useContext, useState } from "react";
import Message from "components/templates/Message";
import { TMessage } from "types/MessageType";
import passwordValidator from "utils/PasswordValidator";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import { ChangePreferences } from "api/auth/ChangePreferences";
import { DeleteAccount } from "api/auth/DeleteAccount";
import { InitiateEmailChange } from "api/email/InitiateEmailChange";
import { ChangePassword } from "api/auth/ChangePassword";
import { SetNewEmail } from "api/email/SetNewEmail";
import { useNavigate } from "react-router-dom";

function AccountDeleter({ setMessage }: { setMessage: (message: TMessage) => void }) {
  const [deleteCollapsed, setDeleteCollapsed] = useState(true);
  const navigate = useNavigate();

  async function DeleteHandler(e: any) {
    e.preventDefault();
    const confirmed = window.confirm(
      "Are you sure you want to delete your account? This cannot be undone.",
    );
    if (confirmed) {
      const password = e.target["password"].value;

      DeleteAccount(password).then((data) => {
        if (data.status === "success") {
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          navigate("/");
        } else {
          setMessage({
            message: data.message,
            type: "danger",
          });
          console.error(data);
        }
      });
    }
  }

  return deleteCollapsed ? (
    <div>
      <button className="btn btn-danger  mt-2" onClick={() => setDeleteCollapsed(false)}>
        Delete Account
      </button>
    </div>
  ) : (
    <>
      <div
        className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
        style={{ top: constants.navHeight, backgroundColor: "#000a" }}
      >
        <form
          onSubmit={DeleteHandler}
          className="px-3 py-2 rounded bg-dark text-bg-dark position-relative"
        >
          <button
            type="button"
            className="btn-close btn-close-white position-absolute top-0 end-0 m-2"
            aria-label="Close"
            onClick={() => setDeleteCollapsed(true)}
          ></button>
          <h3>Be Careful!</h3>
          <div className="my-3">
            Are you sure you want to delete your account? This cannot be undone.
          </div>
          <div className="mb-1">Enter your password below to delete your account:</div>
          <input type="password" className="form-control" id="password" />
          <input type="submit" className="btn btn-danger mt-3 mb-2" value={"Delete Forever"} />
        </form>
      </div>
    </>
  );
}

function PasswordChanger({ setMessage }: { setMessage: (message: TMessage) => void }) {
  const [changePasswordCollapsed, setChangePasswordCollapsed] = useState(true);
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const passwordsMatch = newPassword1 === newPassword2;
  const password1Errors = passwordValidator(newPassword1);

  function ChangePasswordHandler(e: any) {
    e.preventDefault();

    const oldPassword = e.target["oldPassword"].value;
    const newPassword = e.target["newPassword"].value;

    ChangePassword(oldPassword, newPassword).then((data) => {
      if (data.status === "success") {
        setChangePasswordCollapsed(true);

        setMessage({
          message: "Password changed successfully.",
          type: "success",
        });
      } else {
        setMessage({
          message: data.message,
          type: "danger",
        });
        console.error(data);
      }
    });
  }

  return changePasswordCollapsed ? (
    <div>
      <button className="btn btn-primary mt-2 " onClick={() => setChangePasswordCollapsed(false)}>
        Change Password
      </button>
    </div>
  ) : (
    <div className="form-control position-relative mt-2">
      <h3>Change Your Password</h3>
      <form onSubmit={ChangePasswordHandler} className="py-2">
        Current Password
        <input
          type="password"
          id="oldPassword"
          autoComplete="current-password"
          className="form-control"
        />
        New Password
        <input
          type="password"
          id="newPassword"
          autoComplete="new-password"
          className="form-control"
          onChange={(e: any) => setNewPassword1(e.target.value)}
        />
        {password1Errors.length > 0 && (
          <div className="m-2 my-4">
            Your password must have at least:
            <ul>
              {password1Errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        Confirm New Password
        <input
          type="password"
          id="newPassword2"
          autoComplete="new-password"
          className="form-control"
          style={passwordsMatch ? {} : { border: "1px solid red" }}
          onChange={(e: any) => setNewPassword2(e.target.value)}
        />
        {!passwordsMatch && <div className="text-danger">Passwords must match</div>}
        <input
          disabled={!passwordsMatch || password1Errors.length > 0}
          type="submit"
          className="btn btn-primary mt-2"
          value={"Set New Password"}
        />
      </form>
      <button
        type="button"
        defaultChecked={false}
        className="btn-close position-absolute top-0 end-0 m-2"
        aria-label="Close"
        onClick={() => setChangePasswordCollapsed(true)}
      ></button>
    </div>
  );
}

function ChangeEmail({ setMessage }: { setMessage: (message: TMessage) => void }) {
  const [emailCollapsed, setEmailCollapsed] = useState(true);
  const [newEmail, setNewEmail] = useState("");
  const { user, setUser } = useContext(UserContext);

  async function ChangeEmailHandler(e: any) {
    e.preventDefault();

    const newEmail = e.target["newEmail"].value;
    const password = e.target["password"].value;

    const data = await InitiateEmailChange(newEmail, user.email, password);
    if (data.status === "success") {
      setNewEmail(newEmail);

      setMessage({
        message: "Email change initiated. Please verify your new email address.",
        type: "success",
      });
    } else {
      setMessage({
        message: data.message,
        type: "danger",
      });
      console.error(data);
    }
  }

  async function VerifyNewEmailHandler(e: any) {
    e.preventDefault();
    const otp = e.target["otp"].value;
    const data = await SetNewEmail(otp, user.email, newEmail);

    if (data.status === "success") {
      setUser({
        ...user,
        email: newEmail,
      });
      setMessage({
        message: "Email changed successfully.",
        type: "success",
      });
      setEmailCollapsed(true);
      setNewEmail("");
    } else {
      setMessage({
        message: data.message,
        type: "danger",
      });
      console.error(data);
    }
  }

  return emailCollapsed ? (
    <div>
      <button className="btn btn-primary " onClick={() => setEmailCollapsed(false)}>
        Change Email
      </button>
    </div>
  ) : (
    <>
      <div className="form-control position-relative">
        <h3>Update Your Email</h3>
        {!newEmail && (
          <form onSubmit={ChangeEmailHandler} className="py-2">
            Current Password:
            <input type="password" id="password" className="form-control" />
            New Email:
            <input type="email" id="newEmail" className="form-control" />
            <input type="submit" className="btn btn-primary mt-2" value={"Set New Email"} />
          </form>
        )}
        {newEmail && (
          <form onSubmit={VerifyNewEmailHandler} className="py-2">
            check your new email and enter the one-time passcode:
            <input type="text" id="otp" name="otp" maxLength={6} className="form-control" />
            <input
              type="submit"
              className="btn btn-primary mt-2"
              value={"Confirm one-time passcode"}
            />
          </form>
        )}
        <button
          type="button"
          defaultChecked={false}
          className="btn-close position-absolute top-0 end-0 m-2"
          aria-label="Close"
          onClick={() => setEmailCollapsed(true)}
        ></button>
      </div>
    </>
  );
}

function ChangeName({ setMessage }: { setMessage: (message: TMessage) => void }) {
  const { user, setUser } = useContext(UserContext);

  async function ChangePreferencesHandler(e: any) {
    e.preventDefault();
    const firstName = e.target["firstName"].value;
    const lastName = e.target["lastName"].value;
    const data = await ChangePreferences(firstName, lastName);

    if (data.status === "success") {
      setUser({
        ...user,
        firstName: firstName,
        lastName: lastName,
      });
      setMessage({
        message: "Changes saved successfully.",
        type: "success",
      });
    } else {
      setMessage({
        message: data.message,
        type: "danger",
      });
      console.error(data);
    }
  }

  return (
    <form onSubmit={ChangePreferencesHandler} className=" py-2">
      <h1>Settings</h1>
      First Name
      <input type="text" id="firstName" defaultValue={user.firstName} className="form-control" />
      Last Name
      <input type="text" id="lastName" defaultValue={user.lastName} className="form-control" />
      <input type="submit" className="btn btn-primary mt-2" value={"Save Changes"} />
    </form>
  );
}

export default function EditAccount() {
  SetDocumentTitle("Account Settings");
  const [message, setMessage] = useState<TMessage>();

  return (
    <div
      className="m-3 d-flex justify-content-center position-relative w-100"
      style={{ minHeight: window.innerHeight - 100 }}
    >
      <Message
        className={"position-fixed bottom-0 w-75"}
        message={message}
        setMessage={() => setMessage}
      />
      <div className="w-100" style={{ maxWidth: constants.contentWidth }}>
        <ChangeName setMessage={setMessage} />
        <hr />
        <ChangeEmail setMessage={setMessage} />
        <PasswordChanger setMessage={setMessage} />
        <hr />
        <AccountDeleter setMessage={setMessage} />
      </div>
    </div>
  );
}
