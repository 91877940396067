export default function JobsToBeDonePathway() {
  return (
    <div>
      <p>
        The Jobs to be Done framework offers a lens through which businesses can understand the core
        needs or tasks that customers are trying to accomplish with their product or service. This
        approach shifts the focus from the product itself to the outcomes that customers seek. It's
        premised on the idea that people buy products and services to get a "job" done. This job
        could be functional, such as cutting grass with a lawnmower, or emotional, such as feeling
        secure in a vehicle.
      </p>
      <p>
        Understanding the Jobs to be Done enables companies to innovate and improve by aligning
        their offerings more closely with customer needs and desired outcomes. It encourages looking
        beyond superficial customer characteristics to grasp the underlying motivations driving a
        purchase decision. This deep insight into customer behavior helps in developing products and
        services that are not just innovative but are perceived as valuable and essential by
        customers.
      </p>
      <p>
        Employing the Jobs to be Done framework involves conducting in-depth interviews, observing
        customers, and analyzing data to uncover not just what customers are doing, but why they are
        doing it. This understanding can lead to the identification of previously unrecognized
        market opportunities, the redefinition of market segments, and the innovation of products
        and services that more precisely meet customer needs. Ultimately, the Jobs to be Done
        framework is a powerful tool for fostering customer-centric innovation and for guiding
        strategic decisions that ensure long-term business success.
      </p>
      <h4>Examples</h4>
      <p>
        <b>Apple</b> addresses the need for seamless integration of technology in everyday life,
        from professionals enhancing productivity with MacBooks to fitness enthusiasts tracking
        their workouts with the Apple Watch.
      </p>
    </div>
  );
}
