import Example from "components/concepts/templates/Example";
import ExampleContainer from "components/concepts/templates/ExampleContainer";
import Image from "components/concepts/templates/Image";
import RelatedFrameworks from "components/concepts/templates/RelatedFrameworks";

export default function CJobsToBeDonePathway() {
  return (
    <div>
      <p>
        Customers purchase products and services to help them solve their needs. In other words,
        customers "hire" products and services to help them complete "jobs". Knowing what "jobs"
        your customers want done will explain what your products and services should accomplish.
      </p>
      <ol>
        <li>Decide which user segment(s) to target </li>
        <li>Create a list of customers and potential customer to interview </li>
        <li>
          Conduct in-depth interviews and observations to understand what jobs they are trying to do
          with their current products/services.
        </li>
      </ol>
      <p>
        Functional Aspects: Specific tasks that customers must be able to achieve with their hired
        product/service and are usually measurable. What would a solution need to do?
      </p>
      <p>
        Emotional Aspects: The subjective "why" behind a customer's choice. How should a solution
        make a user feel? How should a solution affect others' perception of the user?"
      </p>

      <ExampleContainer>
        <Example header="Gilette">Gilette in India</Example>
        <Example header="Sally's Lemonade Stand">
          <div className="container p-0">
            <div className="row">
              <div className="col-sm-8 col-md-9">
                Sally's lemonade stand hasn't been doing as well since her neighbor, Susie open a
                competing store. Sally does some customer research, asking people in her
                neighborhood why they buy lemonade at the neighborhood lemonade stands. They tell
                her that the main reason they purchase is to support the young neighborhood
                entrepreneurs. Another reason is because a cold lemonade sounds like it would be
                refreshing on a hot summer day. Sally realizes that both she and Susie are
                approaching the customers' jobs to be done the exact same way. In order to increase
                her sales, Sally brainstorms to figure out how she can better address the functional
                job of refreshment on a hot day. She decides to sell a homemade sports drink in
                hopes of delivering more refreshment than lemonade.
              </div>
              <div className="col-sm-4 col-md-3">
                <Image src={require("lib/img/pathways/LemonadeStand.jpg")} alt="Lemonade Stand" />
              </div>
            </div>
          </div>
        </Example>
      </ExampleContainer>

      <Image src={require("lib/img/pathways/JobsToBeDone.png")} alt="Jobs to Be Done" />

      <RelatedFrameworks unfinishedConcepts={["Jobs to Be Done"]} />
    </div>
  );
}
