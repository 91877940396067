// package imports
import React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "App.css";
import "Bootstrap.css";

import App from "App";
import SearchPage from "components/navigation/SearchPage";
import Home from "components/main_pages/Home";
import Codex from "components/main_pages/Codex";
import FocusHome from "components/main_pages/FocusHome";
import Videos from "components/main_pages/Videos";
import PathwayRoot from "components/pathways/PathwayRoot";
import ConceptRoot from "components/concepts/ConceptRoot";
import Tools from "components/tools/ToolsHome";
import Team from "components/main_pages/Team";
import Login from "components/auth/Login";
import Register from "components/auth/Register";
import Payment from "components/auth/Payment";
import { AdminLogin, UserLogin } from "components/auth/LoginWall";
import Account from "components/auth/Account";
import Support from "components/auth/Support";
import ForgotPassword from "components/auth/ForgotPassword";
import EditAccount from "components/auth/EditAccount";
import FocusRoot from "components/focuses/FocusRoot";
import ErrorPage from "components/main_pages/ErrorPage";
import Dashboard, { Loader as DashboardLoader } from "components/main_pages/Dashboard";
import Map from "components/focuses/maps/MainMap";
import QrCode from "components/main_pages/QrCode";
import ToolsRoot from "components/tools/ToolsRoot";
import AnimationTest from "components/main_pages/AnimationTest";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
      },

      {
        path: "/animationtest",
        element: <AnimationTest />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/tools",
        element: <Tools />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/login",
        element: <Login />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/register",
        element: <Register />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/forgotpassword",
        element: <ForgotPassword />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/payment",
        element: <Payment />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/codex",
        element: <Codex />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/videos",
        element: <Videos />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/FocusHome",
        element: <FocusHome />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/team",
        element: <Team />,
        errorElement: <ErrorPage />,
      },

      {
        path: "/map",
        element: <Map />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/qr",
        element: <QrCode />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/focus/:focusId",
        element: <FocusRoot />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/user",
        element: <UserLogin />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/user/search",
            element: <SearchPage />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/user/pathway/:pathwayId",
            element: <PathwayRoot />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/user/concept/:categoryId/:conceptId/",
            element: <ConceptRoot />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/user/concept/:categoryId/:conceptId/:pathwayId",
            element: <ConceptRoot />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/user/tool/:toolId",
            element: <ToolsRoot />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/user/account",
            element: <Account />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/user/account/support",
            element: <Support />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/user/account/editaccount",
            element: <EditAccount />,
            errorElement: <ErrorPage />,
          },
        ],
      },
      {
        path: "/admin",
        element: <AdminLogin />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/admin/dashboard",
            element: <Dashboard />,
            errorElement: <ErrorPage />,
            loader: DashboardLoader,
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
