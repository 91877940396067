import QRCode from "react-qr-code";

export default function QrCode() {
  const ip = "192.168.1.244";
  const value = `http://${ip}:3000/`;

  return (
    <div
      className="d-flex flex-column w-100 justify-content-center align-items-center"
      style={{ minHeight: window.innerHeight - 100 }}
    >
      <QRCode value={value} />
      <div className="mt-2">{value}</div>
    </div>
  );
}
