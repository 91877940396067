import Example from "components/concepts/templates/Example";
import ExampleContainer from "components/concepts/templates/ExampleContainer";
import RelatedFrameworks from "components/concepts/templates/RelatedFrameworks";

export default function EthicsPathway() {
  return (
    <div>
      <p>
        The mission, vision, and values of the company are essential for making a complete strategy,
        in that it defines where we are tryign to get to. Alignment is needed to ensure the entire
        company gets to the destination together. The last piece is to decide how we will compete,
        how we will play the game. Michael Dell wrote a book on this, called "Play Nice, But Win."
        He was referring to making ethical decisions on the path to build your business and compete.
        There are thousands of businesses that have failed due to their illegal or unethical
        practices. Though these decisions can leap a company forward temporarily, they can
        ultimately destroy the company's alignment and image and result in jailtime for those
        involved.
      </p>
      <p>The CEO should be considered the 'chief ethical officer.'</p>
      <ol>
        <li>Gather the facts</li>
        <li>Define the ethical issues</li>
        <li>Identify affected parties</li>
        <li>Apply ethics toolkit</li>
        <li>Develop alternative solutions</li>
        <li>Check your gut</li>
      </ol>

      <ExampleContainer>
        <Example header="Theranos">
          Elizabeth, founder of Theranos, was attending Stanford University and developed an idea
          for a patch that would monitor a person's body through their blood, enabling quick
          response for doctors to their patients. This led to the final product of blood tests in a
          drop. During development, it was clear that technology and science wasn't able to support
          her idea, despite the millions of dollars she raised through investors. Unfortunately, the
          issues with development weren't shared and the pressures to perform and meet expectation
          mounted, to the point that, whatever the purpose of her actions previously, she covered up
          and told lies to hide the product issues. Eventually, employees decided it was better to
          speak out despite signing non-disclosure agreements and Theranos was shut down and
          Elizabeth was sentenced to jail time.
        </Example>
        <Example header="Toyota">
          In 2009, Toyota faced accusation that they had deliberately covered up issues with their
          gas pedals, which caused many personal accidents and deaths with Toyota vehicles. This
          situation was different than Theranos, in that the CEO, Akio Toyoda, was not instrumental
          in allowing the issues to happen. Toyota was slow to respond, but in the end Akio Toyoda
          himself went before congress and personally took responsibility for his company's
          mistakes. In light of the recalls and stop of production, Toyota did not lay off a single
          person during that time, and instead engaged with the community to provide other ways for
          Toyota employees to earn pay.
        </Example>
      </ExampleContainer>
      <RelatedFrameworks
        unfinishedConcepts={[
          "Ethics Toolkit",
          "Fraud Triangle",
          "6 Step Ethical Guide (BYU)",
          "Pressure Leads to Poor Decision Making",
        ]}
      />
    </div>
  );
}
