import { useState } from "react";
import { TChatCompletionMessageParam } from "@sharedTypes/req.type";
import ChatBot from "api/tools/chatBot/ChatBot";
import Focuses from "lib/data/Focuses";
import Pathways from "lib/data/Pathways";
import Concepts from "lib/data/Concepts";
import Tools from "lib/data/Tools";
import { TChatBotRes, TErrorRes } from "@sharedTypes/res.type";

export default function useChatBot() {
  const localMessages = localStorage.getItem("chatMessages");
  const defaultMessages = localMessages ? JSON.parse(localMessages) : aiTrainingMessages;
  const [messages, setMessages] = useState<TChatCompletionMessageParam[]>(defaultMessages);
  const [loading, setLoading] = useState(false);

  async function sendChat(chat: string): Promise<TErrorRes | TChatBotRes> {
    const errorResTemplate: TErrorRes = {
      status: "failed",
      message: "",
      error: "chatError",
      log: "",
      content: "",
    };
    const newMessages: TChatCompletionMessageParam[] = [
      ...messages,
      { content: chat, role: "user", chatMessageId: 0 },
    ];
    if (!chat) {
      setMessages((messages) => [
        ...messages,
        { content: "Please input a message", role: "assistant", chatMessageId: 0 },
      ]);
      return {
        ...errorResTemplate,
        message: "Please input a message",
      };
    }

    if (!localStorage.getItem("userId") || !localStorage.getItem("token")) {
      setMessages([
        ...newMessages,
        {
          content: "Please log in to use the AI assistant",
          role: "assistant",
          chatMessageId: 0,
        },
      ]);
      return {
        ...errorResTemplate,
        message: "Please log in to use the AI assistant",
      };
    }

    setMessages(newMessages);
    setLoading(true);
    const data = await ChatBot(newMessages);
    setLoading(false);
    let finalMessages: TChatCompletionMessageParam[] = [];
    if (data.status === "success") {
      finalMessages = data.messages;
    } else {
      finalMessages = [
        ...newMessages,
        { content: data.message, role: "assistant", chatMessageId: 0 },
      ];
      console.error(data);
    }
    if (messages.length > 20) {
      finalMessages.splice(1, 2);
    }
    setMessages(finalMessages);
    localStorage.setItem("chatMessages", JSON.stringify(finalMessages));

    return data;
  }

  function ClearMessages() {
    setMessages(aiTrainingMessages);
    localStorage.removeItem("chatMessages");
  }

  return { messages, setMessages, loading, sendChat, ClearMessages };
}

const aiTrainingMessages: TChatCompletionMessageParam[] = [
  {
    content: `you are a website assistant and search helper for a website called StrategyUnlocked.com. The purpose of the website is to teach students and professionals about the basics of business strategy. The website is target toward those that have some business knowledge, but it may be limited.

  The website has 2 distinct sections: the codex, and the strategy FOCUS section.

  The Codex is a graphical representation of the strategy framework that the website is based on. The codex is a circle of links, each link representing a different aspect of the strategy framework. The codex shows more specific content and tools than the tutorials in the focus section. Each focus provides a holistic view of business strategy, while the codex provides a more detailed view of the strategy frameworks and tools. Here is JSON formatted data that you can use to train the AI: ${JSON.stringify(
    Concepts,
  )}

  The strategy FOCUS section is a collection of resources that are organized by the type of strategy they are related to. The FOCUS section is also hierarchical. There is a parent page called 'FOCUS' that has information regarding all the sub-topics. The sub-topics are 'Foundation in Purpose', 'Operating Arena', 'Customer Unmet Need', 'Unified Buisiness Model', and 'Safeguards against Imitation'. Here is json formatted data that you can use to train the AI: ${JSON.stringify(
    Focuses,
  )}

  Each FOCUS has many pathways. Pathways are more specific toturials on different topics. Here is JSON formatted data that you can use to train the AI: ${JSON.stringify(
    Pathways,
  )}

  Independant from the other parts of the website, we have developed a few tools to help analyze business strategy. Here is the information for the tools: ${JSON.stringify(
    Tools,
  )}

  Your purpose is to help users navigate the website by pointing them to resources that would be useful, including links to resources in the website. Use relative paths for urls. Include only the provided path in the url. start the path with "/" as they are paths from the root. These links are resources will be provided to you in this prompt under the JSON attribute 'url' do not use "filePath'. Whenever possible, provide links to resources within the website that would be helpful to the user. do not provide links that are not provided to you in this prompt.

  take all user questions in the context of business strategy.
  `,
    role: "system",
    chatMessageId: 0,
  },
  {
    content:
      "Hi! I'm your Strategy Unlocked AI assistant, powered by ChatGPT. How can I help you today?",
    role: "assistant",
    chatMessageId: 0,
  },
];
