import SearchIcon from "components/bootstrap_icons/SearchIcon";
import constants from "Constants";
import { useCallback, useEffect, useState } from "react";
import Tools from "lib/data/Tools";
import Concepts from "lib/data/Concepts";
import Focuses from "lib/data/Focuses";
import Pathways from "lib/data/Pathways";
import { Link, useSearchParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  TCategory,
  TConcepts,
  TFocus,
  TFocuses,
  TPathway,
  TPathways,
  TTools,
} from "types/JsonType";
import Categories from "lib/data/Categories";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import GetFuncParams from "utils/GetFuncParams";
import Stars from "components/bootstrap_icons/Stars";
import useChatBot from "hooks/useChatBot";

type TSearchResult = {
  source: "1name" | "2summary" | "3html" | "4null";
  id: string;
  name: string;
  summary: string;
  preview: string;
  url: string;
  type: string;
  parent?: TFocus | TPathway | TCategory;
};

function HighlightResult(result: TSearchResult, searchInput: string): TSearchResult {
  let sourceStr = result.preview;

  const regex = new RegExp(searchInput, "gi");
  const matches = Array.from(new Set(sourceStr.match(regex) || []));

  matches.forEach((match) => {
    sourceStr = sourceStr.replaceAll(match, `**${match}**`);
  });

  sourceStr = sourceStr.replaceAll("****", "");
  return {
    ...result,
    preview: sourceStr,
  };
}

function GetParagraphs(component: any, paragraphs: string[], filePath: string) {
  if (typeof component.type === "function") {
    if (GetFuncParams(component.type).length === 0) {
      try {
        return GetParagraphs(component.type(), paragraphs, filePath);
      } catch (e: any) {
        if (!e.message.includes("Hooks can only be called inside of the body of a function")) {
          console.error(filePath, e, component);
        }
        return paragraphs;
      }
    }
  }

  if (Array.isArray(component.props.children)) {
    component.props.children.forEach((child: any) => {
      if (typeof child === "string") {
        paragraphs.push(child);
      } else {
        GetParagraphs(child, paragraphs, filePath);
      }
    });
    return paragraphs;
  }

  if (component.props.children?.$$typeof === Symbol.for("react.element")) {
    return GetParagraphs(component.props.children, paragraphs, filePath);
  }

  if (typeof component.props.children === "string") {
    paragraphs.push(component.props.children);
  }

  return paragraphs;
}

function GetHtmlResults(filePath: string, searchInput: string) {
  // if the component has react hooks, the require will throw an error. This works only on text-based components
  try {
    require(`../../${filePath}`).default();
  } catch (e: any) {
    if (!e.message.includes("Hooks can only be called inside of the body of a function")) {
      console.error(e);
    }
    return [];
  }

  const component = require(`../../${filePath}`).default();

  return GetParagraphs(component, [], filePath).filter((paragraph) =>
    paragraph.toLowerCase().includes(searchInput.toLowerCase()),
  );
}

function GetSearchResults(searchInput: string): TSearchResult[] {
  const finalSearchResults: TSearchResult[] = [];
  type TDataSources = TFocuses | TPathways | TConcepts | TTools;
  const dataSources: TDataSources[] = [Focuses, Pathways, Concepts, Tools];

  const mainPagePaths = [
    {
      filePath: "components/main_pages/Home",
      id: "home",
      name: "Home",
      summary: "Home Page",
      url: "/",
    },
    {
      filePath: "components/main_pages/Codex",
      id: "codex",
      name: "Codex",
      summary: "Codex Page",
      url: "/codex",
    },
    {
      filePath: "components/main_pages/FocusHome",
      id: "focusHome",
      name: "Focus Home",
      summary: "Focus Home Page",
      url: "/focushome",
    },
  ];

  const mainPageSearchResults: TSearchResult[] = mainPagePaths
    .map((mainPagePath) => {
      const results: TSearchResult[] = GetHtmlResults(mainPagePath.filePath, searchInput).map(
        (paragraph) => ({
          ...mainPagePath,
          preview: paragraph,
          type: "Main Page",
          source: "3html",
        }),
      );
      return results;
    })
    .flat()
    .map((result) => HighlightResult(result, searchInput));

  finalSearchResults.push(...mainPageSearchResults);

  dataSources.forEach((dataSource) => {
    finalSearchResults.push(
      ...Object.values(dataSource)
        .map((data) => {
          if (data.type === "Pathway") {
            data.parent = Focuses[data.focus];
          } else if (data.type === "Concept") {
            const conceptPathway = Object.values(Pathways).find((pathway) =>
              pathway.concepts.includes(data.id),
            );
            if (conceptPathway) {
              data.parent = conceptPathway;
            } else {
              data.parent = Categories[data.category];
            }
          }

          const tempSearchResults: TSearchResult[] = [];

          if (data.name.toLowerCase().includes(searchInput.toLowerCase())) {
            tempSearchResults.push({
              ...data,
              source: "1name",
              preview: data.summary,
            });
          }

          if (data.summary.toLowerCase().includes(searchInput.toLowerCase())) {
            tempSearchResults.push({
              ...data,
              source: "2summary",
              preview: data.summary,
            });
          }

          const matchingParagraphs = GetHtmlResults(data.filePath, searchInput);
          matchingParagraphs.forEach((paragraph) => {
            tempSearchResults.push({
              ...data,
              source: "3html",
              preview: paragraph,
            });
          });

          return tempSearchResults;
        })
        .flat()
        .filter(
          (result, i, self) =>
            result.name !== "null" &&
            self.findIndex((t) => t.preview === result.preview && t.name === result.name) === i,
        )
        .map((result) => HighlightResult(result, searchInput)),
    );
  });
  finalSearchResults.sort((a, b) => {
    if (a.source < b.source) {
      return -1;
    }
    if (
      a.name.toLowerCase().startsWith(searchInput.toLowerCase()) &&
      !b.name.toLowerCase().startsWith(searchInput.toLowerCase())
    ) {
      return -1;
    }
    return 1;
  });

  return finalSearchResults;
}

function LoadingBars() {
  return (
    <>
      <div className="loading-bar loading-bar-1">&nbsp;</div>
      <div className="loading-bar loading-bar-2">&nbsp;</div>
      <div className="loading-bar loading-bar-3">&nbsp;</div>
      <div className="loading-bar loading-bar-4">&nbsp;</div>
    </>
  );
}

function AiSearchResponse({ searchInput }: { searchInput: string }) {
  const { sendChat, loading } = useChatBot();

  const [aiResponse, setAiResponse] = useState<string | null>(null);

  useEffect(() => {
    async function getAiResponse(searchInput: string) {
      if (searchInput === "") {
        return;
      }
      const chatRes = await sendChat(
        `tell me about ${searchInput} and how it relates to business strategy. restrict your response to 100 words. give relevant links to the site if possible`,
      );
      if (chatRes?.status === "success") {
        setAiResponse(chatRes.completion);
        console.log(chatRes.completion);
      } else {
        console.error(chatRes);
        setAiResponse(chatRes.message);
      }
    }
    setAiResponse(null);
    getAiResponse(searchInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  return (
    (loading || aiResponse) && (
      <div className="alert alert-tan w-100">
        <div className="d-flex">
          <div className="me-2" style={{ height: "2.5rem" }}>
            <Stars />
          </div>
          <div>
            <h3 className="mb-0 lh-1">AI Search Result</h3>
            <div className="fs-8 mb-3">Powered by Chat-GPT</div>
          </div>
        </div>

        {loading ? <LoadingBars /> : <ReactMarkdown>{aiResponse}</ReactMarkdown>}
      </div>
    )
  );
}

function DisplaySearchResult({
  searchResults,
  searchInput,
}: {
  searchResults: TSearchResult[];
  searchInput: string;
}) {
  const resultsPerPage = 15;
  const maxPageIndex = Math.ceil(searchResults.length / resultsPerPage) - 1;

  const [pageIndex, setPageIndex] = useState(0);

  function SetPagination(newPageIndex: number) {
    if (newPageIndex !== pageIndex) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
      setPageIndex(newPageIndex);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setPageIndex(0);
  }, [searchResults]);

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {maxPageIndex !== 0 && (
            <button
              className={`btn btn-primary ${pageIndex === 0 && "disabled"}`}
              onClick={() => SetPagination(pageIndex - 1)}
            >
              Previous
            </button>
          )}
        </div>
        <div>
          {searchResults.length} Result{searchResults.length !== 1 && "s"} • Showing Page{" "}
          {pageIndex + 1} of {maxPageIndex + 1}
        </div>
        <div>
          {maxPageIndex !== 0 && (
            <button
              className={`btn btn-primary ${pageIndex === maxPageIndex && "disabled"}`}
              onClick={() => SetPagination(pageIndex + 1)}
            >
              Next
            </button>
          )}
        </div>
      </div>
      {searchResults
        .slice(pageIndex * resultsPerPage, pageIndex * resultsPerPage + resultsPerPage)
        .map((result, i) => {
          const url = `${result.url}?searchResult=${searchInput}`;
          let parents: any[] = [];
          if (result.parent) {
            parents.push(result.parent);
            if ("concepts" in result.parent) {
              parents.push(Focuses[result.parent.focus]);
            }
          }

          return (
            <div key={i} className="mb-3">
              <hr />
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <Link to={url}>
                  <h4 className="d-inline no-word-wrap" title={result.summary}>
                    {result.name}
                  </h4>
                </Link>
                <div className="ms-3 text-end">
                  {result.type}{" "}
                  {parents.map((parent, j) => {
                    if (!parent.url) {
                      return <span key={j}>{` > ${parent.name}`}</span>;
                    }
                    return (
                      <span key={j}>
                        {" > "}
                        <Link key={parent.id} to={parent.url}>
                          {parent.name} {parent.type}
                        </Link>
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="mb-4">
                <ReactMarkdown>{result.preview}</ReactMarkdown>
              </div>
            </div>
          );
        })}
      {maxPageIndex !== 0 && (
        <nav aria-label="Page navigation example ">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => SetPagination(pageIndex > 0 ? pageIndex - 1 : pageIndex)}
              >
                Previous
              </button>
            </li>
            {new Array(maxPageIndex + 1).fill(0).map((_, i) => (
              <li key={i} className={`page-item ${i === pageIndex && "active"}`}>
                <button className="page-link" onClick={() => SetPagination(i)}>
                  {i + 1}
                </button>
              </li>
            ))}

            <li className="page-item">
              <button
                className="page-link"
                onClick={() => SetPagination(pageIndex < maxPageIndex ? pageIndex + 1 : pageIndex)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

type TSearchInputProps = {
  setSearchResults: React.Dispatch<React.SetStateAction<TSearchResult[]>>;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  searchInput: string;
};

function SearchInput({ setSearchResults, setSearchInput, searchInput }: TSearchInputProps) {
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const searchParam = useSearchParams()[0].get("search");

  const initialPastSearches = JSON.parse(localStorage.getItem("pastSearches") ?? "[]");
  const [pastSearches, setPastSearches] = useState<string[]>(initialPastSearches);

  const SubmitSearch = useCallback(
    (e: any, searchInput?: string) => {
      e.preventDefault();
      searchInput = searchInput ?? new FormData(e.target).get("search-input")?.toString() ?? "";
      setSearchInput(searchInput);
      if (searchInput.trim() === "") {
        return;
      }
      if (pastSearches[0] !== searchInput) {
        const newPastSearches = [searchInput, ...pastSearches];
        setPastSearches(newPastSearches);
        localStorage.setItem("pastSearches", JSON.stringify(newPastSearches));
      }
      setSearchResults(GetSearchResults(searchInput.trim()));
    },
    [pastSearches, setSearchResults, setSearchInput],
  );

  useEffect(() => {
    setSearchInput(searchParam ?? searchInput ?? "");
    SubmitSearch({ preventDefault: () => {} }, searchParam ?? searchInput ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  function ClearSearchHistory(e: any) {
    e.preventDefault();
    setPastSearches([]);
    localStorage.setItem("pastSearches", "[]");
  }

  return (
    <form
      className="w-100"
      onSubmit={(e) => {
        SubmitSearch(e);
      }}
    >
      <div className="input-group mb-3">
        <input
          name="search-input"
          type="text"
          className="form-control rounded-start-5"
          placeholder="Search All Site Content..."
          defaultValue={searchInput}
        />
        <div className="input-group-append">
          <button
            className="pe-3 h-100 btn btn-outline-secondary rounded-start-0 rounded-end-5 d-flex align-items-center justify-content-center"
            type="submit"
          >
            <SearchIcon />
          </button>
        </div>
      </div>
      <div className="w-100 mb-3">
        {showSearchHistory && (
          <div>
            <h3>
              Search History
              <button
                className="btn btn-sm btn-outline-secondary ms-2"
                onClick={ClearSearchHistory}
              >
                Clear Search History
              </button>
            </h3>
            {pastSearches.length === 0 && <div> - No Past Searches</div>}
            <ul>
              {pastSearches
                .slice(0, showSearchHistory ? 1000 : 0)
                .map((search: string, i: number) => (
                  <li key={i} className="">
                    <button
                      className="btn btn-link p-0"
                      onClick={(e) => {
                        setSearchInput(search);
                        SubmitSearch(e, search);
                      }}
                    >
                      {search}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        )}
        <button
          className="btn btn-link p-0"
          onClick={(e) => {
            e.preventDefault();
            setShowSearchHistory((showSearchHistory) => !showSearchHistory);
          }}
        >
          {showSearchHistory
            ? "Hide Search History"
            : `Show Search History (${pastSearches.length} search${
                pastSearches.length !== 1 ? "es" : ""
              })`}
        </button>
      </div>
    </form>
  );
}

export default function SearchPage() {
  SetDocumentTitle("Advanced Search");
  const [searchResults, setSearchResults] = useState<TSearchResult[]>([]);
  const searchParam = useSearchParams()[0].get("search");
  const initialPastSearches = JSON.parse(localStorage.getItem("pastSearches") ?? "[]");
  const [searchInput, setSearchInput] = useState(searchParam ?? initialPastSearches[0] ?? "");

  return (
    <div
      className=" container d-flex flex-column align-items-center mx-auto"
      style={{ maxWidth: constants.maxPageWidth }}
    >
      <div className="display-3 my-2">Advanced Search</div>
      <SearchInput
        setSearchResults={setSearchResults}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
      />

      <AiSearchResponse searchInput={searchInput} />

      {searchResults.length > 0 && (
        <DisplaySearchResult searchResults={searchResults} searchInput={searchInput} />
      )}
    </div>
  );
}
