import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OtpVerifier from "components/auth/OtpVerifier";
import passwordValidator from "utils/PasswordValidator";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import { ResetPassword } from "api/auth/ResetPassword";
import UserContext from "context/UserContext";

import LoginBG from "lib/img/loginBG.png";

export default function ForgotPassword() {
  SetDocumentTitle("Forgot Password");
  const [errorMessage, setErrorMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [otpToken, setOtpToken] = useState("");

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const navigate = useNavigate();
  let passwordsMatch = password1 === password2;
  let password1Errors = passwordValidator(password1);

  const searchParams = useSearchParams()[0];
  let return_url = searchParams.get("return_url") ?? "/";

  // see if they're already logged in. verify token
  const { loginStatus } = useContext(UserContext);
  if (loginStatus === "logged in") {
    navigate(return_url);
  }

  function EnterEmailHandler(e: any) {
    e.preventDefault();
    setUserEmail(e.target["email"].value);
  }

  async function NewPasswordHandler(e: any) {
    e.preventDefault();
    const password = e.target["password1"].value;

    const data = await ResetPassword(userEmail, password, otpToken);
    if (data.status === "success") {
      navigate("/login/?return_url=" + return_url + "&verified=true");
    } else {
      document.getElementById("error-anchor")?.scrollIntoView();
      setErrorMessage(data.message);
      console.error(data);
    }
  }

  return (
    <div className="w-100">
      <div className="" style={{ minHeight: window.innerHeight }}>
        <div>
          <div
            id="login-bg"
            className="d-flex position-relative flex-wrap justify-content-between"
            style={{
              minHeight: window.innerHeight,
            }}
          >
            <img
              className="position-fixed object-fit-cover h-100 w-100 z-n1"
              src={LoginBG}
              alt="strategy unlocked"
              style={{ opacity: "0.5", objectPosition: "right" }}
            />
            {/* input card */}
            <div className=" p-2 p-md-5 " style={{ width: 600, maxWidth: 600 }}>
              <div className="d-block p-4 w-100 shadow bg-body mx-auto mx-md-0 rounded">
                <div className="">
                  <h1>Forgot Your Password?</h1>
                  <div className="position-relative">
                    <div className="position-absolute" style={{ top: -100 }} id="error-anchor" />
                  </div>
                  {errorMessage && (
                    <div className=" my-4">
                      <div className="alert alert-danger">
                        <button
                          type="button"
                          className="btn-close position-absolute top-0 end-0 m-2"
                          aria-label="Close"
                          onClick={() => setErrorMessage("")}
                        ></button>
                        {errorMessage}
                      </div>
                    </div>
                  )}
                  <div className="lead d-flex flex-wrap">We're here to help!</div>
                  <p className="mt-2">
                    Type in your email below to get an email that will help you reset your password
                  </p>
                </div>
                <div>
                  {!userEmail && (
                    <form onSubmit={EnterEmailHandler} className="form-control">
                      <div className="m-2 my-4">
                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          name="email"
                          className="form-control"
                          type="email"
                          autoComplete="email"
                          placeholder="example@domain.com"
                          required
                        />
                      </div>

                      <div className="d-flex">
                        <input
                          type="submit"
                          className="w-100 btn btn-primary m-2 mt-3"
                          value={"Get Verification Email"}
                        />
                      </div>
                    </form>
                  )}

                  <OtpVerifier
                    email={userEmail}
                    setEmailVerified={setEmailVerified}
                    showVerifier={!emailVerified && userEmail !== ""}
                    setOtpToken={setOtpToken}
                    forgotPassword={true}
                  />

                  {emailVerified && (
                    <div className=" my-4">
                      <div className="alert alert-success">
                        <div>
                          <b>Success!</b> Your email has been verified. You are ready to choose a
                          new password
                          <form onSubmit={NewPasswordHandler} className="form-control">
                            <div className="m-2 my-4">
                              <label htmlFor="password1" className="">
                                <div>Password</div>
                              </label>

                              <input
                                id="password1"
                                name="password1"
                                className="form-control"
                                type="password"
                                autoComplete="new-password"
                                required
                                onChange={(e: any) => setPassword1(e.target.value)}
                              />
                            </div>
                            {password1Errors.length > 0 && (
                              <div className="m-2 my-4">
                                Your password must have at least:
                                <ul>
                                  {password1Errors.map((error) => (
                                    <li key={error}>{error}</li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            <div className="m-2 my-4">
                              <label htmlFor="password2" className="">
                                <div>Confirm Password</div>
                              </label>

                              <input
                                id="password2"
                                name="password2"
                                className="form-control"
                                type="password"
                                autoComplete="new-password"
                                required
                                onChange={(e: any) => setPassword2(e.target.value)}
                                style={passwordsMatch ? {} : { border: "1px solid red" }}
                              />
                            </div>

                            <div className="d-flex">
                              <input
                                type="submit"
                                className="w-100 btn btn-primary m-2 mt-3"
                                value={"Reset Password"}
                                disabled={!passwordsMatch || password1Errors.length > 0}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
