import { useContext } from "react";
import { isMobile } from "react-device-detect";
import RawConcepts from "lib/data/Concepts";
import Categories from "lib/data/Categories";
import Pathways from "lib/data/Pathways";
import Focuses from "lib/data/Focuses";
import { Link } from "react-router-dom";
import { useState } from "react";
import { TConcepts } from "types/JsonType";
import BootstrapMenuIcon from "components/bootstrap_icons/Menu";
import {
  GetCodexCategories,
  GetCodexPathways,
  GetCodexFocuses,
  TCodexView,
  TConceptView,
} from "lib/data/CodexViews";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import constants from "Constants";
import SidebarPage, { SidebarContext } from "../templates/SidebarPage";

let Concepts = RawConcepts;

type TCodexSortBy = "category" | "pathway" | "focus";

export type TSummaryCard = {
  concept: string;
  category: string;
  summary: string;
};

function GetCoords(degrees: number, radius: number) {
  const x = radius * Math.cos((degrees * Math.PI) / 180);
  const y = radius * Math.sin((degrees * Math.PI) / 180);
  return { x, y };
}

type TConceptProps = {
  degrees: number;
  transform: string;
  concept: TConceptView;
  setSummaryCard: (summaryCard: TSummaryCard) => void;
  backgroundRingRadius: number;
  conceptRingRadius: number;
  handlePointerDown: (e: any) => void;
  slice: TCodexView;
};
function Concept({
  slice,
  concept,
  backgroundRingRadius,
  conceptRingRadius,
  degrees,
  transform,
  setSummaryCard,
  handlePointerDown,
}: TConceptProps) {
  const flip = degrees > 90 && degrees < 270;

  const mouseEnterHandler = () => {
    const summaryCircle = document.getElementById("summary-circle");
    if (summaryCircle) {
      summaryCircle.style.opacity = "1";
    }
    setSummaryCard({
      concept: concept.name,
      category: slice.name,
      summary: concept.summary,
    });
  };

  // .94 is arbitrary. It makes the foreignObject look good. The foreignObject is used to make the text clickable.
  const foreignObjectWidth = (backgroundRingRadius - conceptRingRadius) * 0.94;

  const pathwayPath = concept.pathwayId ? "/" + concept.pathwayId : "";

  return (
    <g transform={transform} fill="black">
      <foreignObject
        x={0}
        y={0}
        width={foreignObjectWidth}
        height={1}
        style={{ cursor: "pointer", overflow: "visible" }}
        transform={`rotate(${flip ? 180 : 0} ${foreignObjectWidth / 2} 0)`}
      >
        <div
          className={"d-flex align-items-center" + (flip ? " justify-content-end text-end" : "")}
          style={{ height: 1, lineHeight: 1, overflow: "visible" }}
          onPointerDown={handlePointerDown}
        >
          <Link className="no-underline " to={concept.url + pathwayPath}>
            <span
              className="text-body fw-bold emphasize-on-hover open-sans"
              onMouseEnter={mouseEnterHandler}
            >
              {concept.name}
            </span>
          </Link>
        </div>
      </foreignObject>
    </g>
  );
}

type TTitleProps = {
  rotation: number;
  titleTransform: string;
  slice: TCodexView;
  circleTransform: string;
  titleCircleRadius: number;
  mainBg: string;
};
function Title({
  rotation,
  titleTransform,
  slice,
  circleTransform,
  titleCircleRadius: circleRadius,
  mainBg,
}: TTitleProps) {
  let textAnchor;

  if ((rotation > 85 && rotation < 95) || (rotation > 265 && rotation < 275)) {
    textAnchor = "middle";
  } else if (rotation >= 275 || rotation <= 85) {
    textAnchor = "start";
  } else {
    textAnchor = "end";
  }

  return (
    <>
      <g>
        <g transform={circleTransform}>
          <circle r={circleRadius} fill={constants.colors.tan3} strokeWidth={".5px"} />
        </g>
        <g transform={titleTransform} fontWeight="bold">
          <g
            textAnchor={textAnchor}
            dominantBaseline={"middle"}
            transform={"rotate(" + -rotation + ")"}
            fill={"black"}
          >
            {slice.url ? (
              <Link to={slice.url} className="no-underline ">
                <text fill={"black"} className="underline-on-hover">
                  {slice.name}
                </text>
              </Link>
            ) : (
              <text>{slice.name}</text>
            )}
          </g>
        </g>
      </g>
    </>
  );
}

type TCodexSvgProps = {
  codexHeightProp?: number;
  codexWidthProp?: number;
  codexSortBy?: TCodexSortBy;
};
export function CodexSvg({
  codexHeightProp,
  codexWidthProp,
  codexSortBy = "category",
}: TCodexSvgProps) {
  const [summaryCard, setSummaryCard] = useState<TSummaryCard>();

  const codexAspectRatio = 1.4;
  let codexWidthFromHeight;
  if (codexHeightProp) {
    codexWidthFromHeight = codexHeightProp * codexAspectRatio;
  }

  const codexWidth = codexWidthProp ?? codexWidthFromHeight ?? window.innerWidth * 0.95;
  const codexHeight = codexHeightProp ?? codexWidth / codexAspectRatio;

  // sizing constants
  // all of these are arbitrary and were chosen to make the codex feel proportional
  const mainBg = constants.colors.tan2;
  const titleFontSize = codexHeight / 50;
  const backgroundRingRadius = codexHeight / 2.15;
  // const outerSliceRadius = codexHeight / 1.85;
  const conceptRingRadius = codexHeight / 4;
  const summaryRingRadius = codexHeight / 4.1;
  const summaryConceptFontSize = codexHeight / 47.5;
  const summaryCategoryFontSize = codexHeight / 53.5;
  const titleRingRadius = codexHeight / 2.05;
  const titleCircleRadius = codexHeight / 100;
  const LogoSize = codexHeight / 2.5;
  const viewboxMultiplier = 1.1;

  const codexViewbox = `${-codexWidth * 0.5 * viewboxMultiplier} ${
    -codexHeight * 0.5 * viewboxMultiplier
  } ${codexWidth * viewboxMultiplier} ${codexHeight * viewboxMultiplier}`;

  let Slices: TCodexView[];
  let conceptCount = 0;

  if (codexSortBy === "category") {
    Slices = GetCodexCategories(Categories, Concepts);
  } else if (codexSortBy === "pathway") {
    Slices = GetCodexPathways(Pathways, Concepts);
  } else {
    Slices = GetCodexFocuses(Focuses, Pathways, Concepts);
  }
  const totalConcepts = Slices.reduce((acc, slice) => acc + slice.concepts.length + 1, 0);
  const degreesPerConcept = 360 / totalConcepts;
  // this proportion makes the font size look good whether there are lots of concepts or few. The formula is arbitrary.
  const codexDensity = Math.pow(totalConcepts, 1 / 3) * 18;
  const conceptFontSize = codexHeight / codexDensity;

  // this default value is arbitrary. Just pick something that looks good.
  const [startAngle, setStartAngle] = useState(-90);

  let angleDiff = 0;
  let rotationActive = false;

  const handlePointerDown = (e: any) => {
    const bgCircle = document.getElementById("background-ring");
    if (!bgCircle) return;
    const bbox = bgCircle.getBoundingClientRect();
    const x = e.clientX - bbox.left - backgroundRingRadius;
    const y = e.clientY - bbox.top - backgroundRingRadius;
    const clientAngle = ((Math.atan2(y, x) * 180) / Math.PI + 360) % 360;
    angleDiff = startAngle - clientAngle;
    rotationActive = true;
  };

  const handlePointerMove = (e: any) => {
    const bgCircle = document.getElementById("background-ring");
    if (!bgCircle) return;
    const bbox = bgCircle.getBoundingClientRect();
    const x = e.clientX - bbox.left - backgroundRingRadius;
    const y = e.clientY - bbox.top - backgroundRingRadius;
    const clientAngle = ((Math.atan2(y, x) * 180) / Math.PI + 360) % 360;

    if (rotationActive && !isMobile) {
      setStartAngle((clientAngle + angleDiff + 360) % 360);
    }
  };

  const handlePointerUp = (e: any) => {
    if (rotationActive === true) {
      rotationActive = false;
    }
  };

  window.addEventListener("pointermove", handlePointerMove);
  window.addEventListener("pointerup", handlePointerUp);
  window.addEventListener("dragend", handlePointerUp);

  return (
    <div className="d-flex" style={{ userSelect: "none" }}>
      <div className="mx-auto">
        <svg viewBox={codexViewbox} width={codexWidth} height={codexHeight}>
          {/* mainBg background circle */}
          <circle
            r={backgroundRingRadius}
            fill={mainBg}
            id="background-ring"
            onPointerDown={handlePointerDown}
            style={{ cursor: "pointer" }}
          />

          {Slices.map((slice, i) => {
            conceptCount += 1;

            const degrees = (conceptCount * degreesPerConcept + startAngle + 360) % 360;
            const lineEndpoint = GetCoords(degrees, backgroundRingRadius);

            const sliceArcLength = ((slice.concepts.length + 1) * degreesPerConcept) % 360;
            const titleRotation = degrees + sliceArcLength / 2;

            const titleTransform =
              "rotate(" + titleRotation + ") translate(" + titleRingRadius + ")";
            const circleTransform =
              "rotate(" + titleRotation + ") translate(" + backgroundRingRadius + ")";

            return (
              <g key={slice.id}>
                <line
                  x1={0}
                  y1={0}
                  x2={lineEndpoint.x}
                  y2={lineEndpoint.y}
                  stroke={constants.colors.tan3}
                  strokeWidth="1"
                  onPointerMove={handlePointerMove}
                />
                {slice.concepts.map((concept, j) => {
                  conceptCount += 1;
                  const degrees = (conceptCount * degreesPerConcept + startAngle) % 360;
                  const transform = "rotate(" + degrees + ") translate(" + conceptRingRadius + ")";

                  return (
                    <g fontSize={conceptFontSize} key={j}>
                      <Concept
                        degrees={degrees}
                        transform={transform}
                        concept={concept}
                        setSummaryCard={setSummaryCard}
                        backgroundRingRadius={backgroundRingRadius}
                        conceptRingRadius={conceptRingRadius}
                        handlePointerDown={handlePointerDown}
                        slice={slice}
                      />
                    </g>
                  );
                })}
                <g fill="white" fontSize={titleFontSize}>
                  <Title
                    mainBg={mainBg}
                    rotation={titleRotation}
                    titleTransform={titleTransform}
                    slice={slice}
                    circleTransform={circleTransform}
                    titleCircleRadius={titleCircleRadius}
                  />
                </g>
              </g>
            );
          })}

          {/* Center Logo */}
          <circle r={LogoSize / 2} fill={mainBg} />
          <image
            x={-LogoSize / 2}
            y={-LogoSize / 2}
            width={LogoSize}
            height={LogoSize}
            href={require("lib/img/Logo.svg").default}
            filter={constants.colors.greyRedCssFilter}
            className="codex-logo-spin"
          />

          {/* summary circle */}
          {summaryCard && (
            <g id="summary-circle" style={{ opacity: 0, transition: "all 0.5s" }}>
              <circle r={summaryRingRadius} fill="white" />
              <text
                textAnchor="middle"
                y={-summaryRingRadius * 0.5}
                fontSize={summaryConceptFontSize}
                fontWeight={"bold"}
                fill={"black"}
              >
                {summaryCard.concept}
              </text>
              <text
                textAnchor="middle"
                y={-summaryRingRadius * 0.35}
                fill={"black"}
                fontSize={summaryCategoryFontSize}
              >
                {Categories[summaryCard.category]?.name ?? summaryCard.category}
              </text>
              <line
                x1={-summaryRingRadius * 0.6}
                y1={-summaryRingRadius * 0.25}
                x2={summaryRingRadius * 0.6}
                y2={-summaryRingRadius * 0.25}
                stroke={"black"}
              />
              <foreignObject
                x={-summaryRingRadius * 0.75}
                y={-summaryRingRadius * 0.2}
                width={summaryRingRadius * 1.5}
                height={summaryRingRadius * 0.9}
              >
                <p className="text-center text-body" style={{ fontSize: summaryCategoryFontSize }}>
                  {summaryCard.summary}
                </p>
              </foreignObject>
            </g>
          )}
          {/* <g stroke="black">
            <line y1={-codexHeight} y2={codexHeight} x={0} />
            <line x1={-codexWidth} x2={codexWidth} y={0} />
          </g> */}
        </svg>
      </div>
    </div>
  );
}

type TKeyProps = {
  codexSortBy: TCodexSortBy;
  setCodexSortBy: (sortBy: TCodexSortBy) => void;
  setShowOnlyEssentials: (finished: boolean) => void;
  showOnlyEssentials: boolean;
  setCodexWidth: (width: number) => void;
  codexWidth: number;
  defaultCodexWidth: number;
};

function Key({
  codexSortBy,
  setCodexSortBy,
  setShowOnlyEssentials,
  showOnlyEssentials,
  setCodexWidth,
  codexWidth,
  defaultCodexWidth,
}: TKeyProps) {
  const { sidebarCollapsed } = useContext(SidebarContext);
  const btnClass = "btn btn-secondary border rounded-0 px-1 fs-7 ";
  const focusClass = "btn btn-info border rounded-0 px-1 ";

  const ZoomInMouseDownHandler = () => {
    let count = 0;
    const interval = setInterval(() => {
      count++;
      setCodexWidth(codexWidth * Math.pow(1.05, count));
    }, 100);

    window.addEventListener("mouseup", () => {
      clearInterval(interval);
    });
  };

  const ZoomOutMouseDownHandler = () => {
    let count = 0;
    const interval = setInterval(() => {
      count++;
      setCodexWidth(codexWidth * Math.pow(0.9, count));
    }, 100);

    window.addEventListener("mouseup", () => {
      clearInterval(interval);
    });
  };

  function ZoomIn() {
    setCodexWidth(codexWidth * 1.05);
  }

  function ZoomOut() {
    setCodexWidth(codexWidth * 0.9);
  }

  function ResetZoom() {
    setCodexWidth(defaultCodexWidth);
  }

  if (sidebarCollapsed) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ width: constants.sidebarCollapsedWidth + "em" }}
      >
        <div className="mt-2" style={{ width: constants.sidebarCollapsedWidth / 2 + "em" }}>
          <BootstrapMenuIcon />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-between flex-wrap p-4 mt-3">
      <h3 className="text-center w-100">Options</h3>
      <button
        className="btn btn-info w-100  rounded-0"
        onClick={() => setShowOnlyEssentials(!showOnlyEssentials)}
      >
        {showOnlyEssentials ? "See All Concepts" : "Show Only the Essentials"}
      </button>
      <div className="w-100 text-center mt-2">Sort By</div>
      <hr className="w-100 mt-0" />
      <button
        className={codexSortBy === "category" ? focusClass : btnClass}
        style={{ width: "30%" }}
        onClick={() => setCodexSortBy("category")}
      >
        Category
      </button>
      <button
        className={codexSortBy === "focus" ? focusClass : btnClass}
        style={{ width: "30%" }}
        onClick={() => setCodexSortBy("focus")}
      >
        Focus
      </button>
      <button
        className={codexSortBy === "pathway" ? focusClass : btnClass}
        style={{ width: "30%" }}
        onClick={() => setCodexSortBy("pathway")}
      >
        Pathway
      </button>
      <div className="w-100 text-center mt-2"> Zoom</div>
      <hr className="w-100 mt-0" />
      <button
        className={btnClass}
        style={{ width: "30%" }}
        onClick={() => ZoomIn()}
        onMouseDown={ZoomInMouseDownHandler}
      >
        Zoom In
      </button>
      <button
        className={btnClass}
        style={{ width: "30%" }}
        onClick={() => ZoomOut()}
        onMouseDown={ZoomOutMouseDownHandler}
      >
        Zoom Out
      </button>
      <button className={btnClass} style={{ width: "30%" }} onClick={() => ResetZoom()}>
        Reset
      </button>
      <div className="w-100 fs-6 mt-3 lead">
        Tip: Spin the codex by clicking and dragging the background circle.
      </div>
    </div>
  );
}

function filteredConcepts(): TConcepts {
  let filteredConcepts: TConcepts = {};
  for (let conceptId in Concepts) {
    if (Concepts[conceptId].essential) {
      filteredConcepts[conceptId] = Concepts[conceptId];
    }
  }
  return filteredConcepts;
}

export default function Codex() {
  SetDocumentTitle("Codex");

  const availableWidth = window.innerWidth * 0.95;
  const defaultCodexWidth = availableWidth < 1000 ? availableWidth : 1000;
  const [codexWidth, setCodexWidth] = useState(defaultCodexWidth);
  const [codexSortBy, setCodexSortBy] = useState<TCodexSortBy>("category");
  const [showOnlyEssentials, setShowOnlyEssentials] = useState(false);

  Concepts = showOnlyEssentials ? filteredConcepts() : RawConcepts;

  return (
    <SidebarPage.Page sidebarDefaultCollapsed={true}>
      <SidebarPage.Sidebar>
        <Key
          codexSortBy={codexSortBy}
          setCodexSortBy={setCodexSortBy}
          showOnlyEssentials={showOnlyEssentials}
          setShowOnlyEssentials={setShowOnlyEssentials}
          codexWidth={codexWidth}
          setCodexWidth={setCodexWidth}
          defaultCodexWidth={defaultCodexWidth}
        />
      </SidebarPage.Sidebar>
      <SidebarPage.Content>
        <div className="overflow-auto py-5">
          <PageHeader />
          <CodexSvg codexWidthProp={codexWidth} codexSortBy={codexSortBy} />
        </div>
      </SidebarPage.Content>
    </SidebarPage.Page>
  );
}
function PageHeader() {
  return (
    <div className="text-center mx-auto  mb-3" style={{ maxWidth: constants.contentWidth }}>
      <div className="display-4 noto-serif fw-semibold">The Strategy Codex</div>
      <div className="lead">
        Welcome to the Strategy Codex! Hover over a concept to learn more about it, or click on it
        to explore in detail.
      </div>
      <div className="text-grey-red">Click and drag the Codex to spin it</div>
    </div>
  );
}
