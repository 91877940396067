import Example from "components/concepts/templates/Example";
import ExampleContainer from "components/concepts/templates/ExampleContainer";

export default function AlignmentPathway() {
  return (
    <div>
      <p>
        The company's mission, vision, and values might be the best in the industry, but if the
        employees cannot align with that purpose, the company will fail. Alignment is truly the most
        important aspect of a company's growth or demise. Mission, vision, and values , in effect,
        define the ultimate direction of the company and how it will act. Alignment is the driving
        force of the company in that chosen direction. Wherever the arrow is pointed, true alignment
        of the leadership, employees and customers will get it to grow. It is this quality that most
        influences investors decision when listening to a pitch; a sense of magic, growth,
        direction, and power.
      </p>

      <ol>
        <li>Develop mission, vision, and values that are inspiring</li>
        <li>Leadership must adopt and love the mission, vision, and values</li>
        <li>
          Employees must be included in the workings of the company and have ownership in their work
        </li>
        <li>
          Recruiting guidelines must reflect the people who want to adopt the company mission,
          vision, and values
        </li>
        <li>
          There should be positive, consistent, and valuable reinforcement of the mission, vision,
          and values and quick (not delayed), consistent, and direct (state the issue clearly, don't
          hide your intention) discouragement of actions that conflict.
        </li>
      </ol>

      <ExampleContainer>
        <Example header="OpenAi">
          In a surprise move, some of OpenAI's board members decided to fire CEO Sam Altman, stating
          he "was not consistently candid in his communications with the board, hindering its
          ability to exercise its responsibilities." Because his employees were so aligned with him
          and the vision of the company, many of OpenAI's lead researchers and a board member quit
          in protest, in addition to the written threat of 700 OpenAI employees to quit and take a
          job at Microsoft. The alignment of each employee at OpenAI (except the board members who
          fired Sam Altman) is powerful and contributes to its success of developing new products
          and being the leader in the AI space.
        </Example>

        <Example header="Toyota North America">
          Toyota is the largest automobile manufacturer in the world (as of May 2024) and continues
          to produce excellent products. Toyota's alignment is well known as 'The Toyota Way', which
          was written by an outsider who watched how Toyota did business. Within Toyota, there is no
          discussion about 'The Toyota Way' book ,rather they daily teach the values that make
          Toyota who they are. The most prominent is 'Kaizen,' the concept of continuous improvement
          in every aspect of the company. Toyota has been able to make this a part of their core and
          it permeates into all its employees, which then results in meetings that are not about who
          made a mistake, but how they will (1) fix the mistake and (2) how they can change the
          process to help the mistake isn't repeated. Another example from Toyota is when Toyota was
          accused of unintended acceleration. This caused many of the manufacturing plants to stop
          the line due to lack of sales. Instead of laying their employees off, Toyota gave their
          employees paid work to clean and update the factory and the local city parks where their
          employees live. Toyota leadership is aligned with one of its core values, teamwork, and
          knew that their employees are absolutely necessary for Toyota to be successful.
        </Example>
      </ExampleContainer>
    </div>
  );
}
