import Definition from "../templates/Definition";
import ExampleContainer from "../templates/ExampleContainer";
import Image from "../templates/Image";
import ScaleCurveImg from "lib/img/concepts/ScaleCurve.png";

export default function ScaleCurve() {
  return (
    <div>
      <Definition>
        <p>
          While similar to an experience curve, a scale curve does not take into account the
          cumulative production of a firm and instead only takes into account a firm’s units
          produced in a given period of time. The scale curve does not indicate how our costs change
          over time, rather it measures how our costs change with an increase or decrease of
          production capacity. The scale curve demonstrates that as a firm produces more of one
          good, its price per unit decrease. When a firm decreases its cost per unit by increasing
          its volume of production, this is referred to as achieving economies of scale. It is
          important to know that it is possible for firms to experience diseconomies of scale, this
          is when an increase in production also increases the cost per unit produced.
        </p>
        <p>To build a scale curve, a firm needs:</p>
        <ol>
          <li>Output over a given period of time (often viewed annually)</li>
          <li>Cost per unit produced (total costs / units produced)</li>
        </ol>
        <p>
          The total output is plotted on the x-axis while the cost per unit is plotted on the
          y-axis. The scale curve also demonstrates that it is likely for cost per unit to increase
          if a firm produces above its efficient capacity.
        </p>
        <p>
          The scale curve answers the question of: Should we increase or decrease production? How do
          we lower our costs? What can a firm do to become more efficient?
        </p>
      </Definition>
      <ExampleContainer>
        <div className="mb-4">
          Utilities in the United States are almost entirely made up of government-regulated
          monopolies. This is due to the economies of scale experienced in industries like energy
          production. When generating electricity, the cost per kilowatt (unit of energy) is
          dramatically decreased as the production of electricity is scaled upward. Therefore, the
          government allows these monopolies in order to drive the cost of electricity, water, and
          waste downwards
        </div>
      </ExampleContainer>
      <Image src={ScaleCurveImg} alt="" />
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}
